import React, { useState } from "react";
import axios from "axios";
import styles from "./style.module.scss";
import validator from "validator";

export default function Index() {
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("+880");
  const [birthDate, setBirthDate] = useState("");
  const [gender, setGender] = useState("");
  const [address, setAddress] = useState("");
  const [levelOfEducation, setLevelOfEducation] = useState("");
  const [howDidLearned, setHowDidLearned] = useState("");
  const [haveLaptopComputer, setHaveLaptopComputer] = useState("");
  const [fullTimeEmployed, setFullTimeEmployed] = useState("");
  const [proficiencyInEnglish, setProficiencyInEnglish] = useState("");

  const [msg, setMsg] = useState(false);
  const [error, setError] = useState(false);
  const [validName, setValidName] = useState("");
  const [validAddress, setValidAddress] = useState("");
  const [validPhone, setValidPhone] = useState("");
  const [validSurname, setValidSurname] = useState("");
  const [validEmail, setValidEmail] = useState("");
  const [validBirthDate, setValidBirthDate] = useState("");
  const [validGender, setValidGender] = useState("");
  const [validLevelOfEducation, setValidLevelOfEducation] = useState("");
  const [validHowDidLearned, setValidHowDidLearned] = useState("");
  const [validHaveLaptopComputer, setValidHaveLaptopComputer] = useState("");
  const [validFullTimeEmployed, setValidFullTimeEmployed] = useState("");
  const [validProficiencyInEnglish, setValidProficiencyInEnglish] = useState("");

  const onChange = (e) => {
    switch (e.name) {
      case "name":
        setName(e.value);
        break;
      case "surname":
        setSurname(e.value);
        break;
      case "email":
        setEmail(e.value);
        break;
      case "phone":
        setPhone(e.value);
        break;
      case "address":
        setAddress(e.value);
        break;
      case "Birth-Date":
        setBirthDate(e.value);
        break;
      case "gender":
        setGender(e.value);
        break;
      case "level-of-education":
        setLevelOfEducation(e.value);
        break;
      case "how-did-learned":
        setHowDidLearned(e.value);
        break;
      case "laptop-computer":
        setHaveLaptopComputer(e.value);
        break;
      case "full-time-employed":
        setFullTimeEmployed(e.value);
        break;
      case "proficiency-in-english" :
        setProficiencyInEnglish(e.value);
        break;
    }
  };

  function clearForm() {
    setName("");
    setSurname("");
    setEmail("");
    setPhone("+880");
    setAddress("");
    setBirthDate("");
    setGender("");
    setFullTimeEmployed("");
    setHaveLaptopComputer("");
    setLevelOfEducation("");
    setProficiencyInEnglish("");
    setHowDidLearned("");
  }

  const handelSubmit = async (e) => {
    e.preventDefault();

    name.length < 3 ? setValidName(false) : setValidName(true);
    surname.length < 3 ? setValidSurname(false) : setValidSurname(true);
    address.length < 3 ? setValidAddress(false) : setValidAddress(true);
    phone.length < 5 ? setValidPhone(false) : setValidPhone(true);
    birthDate.length < 5 ? setValidBirthDate(false) : setValidBirthDate(true);
    gender.length < 1 ? setValidGender(false) : setValidGender(true);
    levelOfEducation.length < 1
      ? setValidLevelOfEducation(false)
      : setValidLevelOfEducation(true);
    howDidLearned.length < 1 ? setValidHowDidLearned(false) : setValidHowDidLearned(true);
    haveLaptopComputer.length < 1 ? setValidHaveLaptopComputer(false) : setValidHaveLaptopComputer(true);
    fullTimeEmployed.length < 1 ? setValidFullTimeEmployed(false) : setValidFullTimeEmployed(true);
    proficiencyInEnglish.length < 1 ? setValidProficiencyInEnglish(false) : setValidProficiencyInEnglish(true);
    !validator.isEmail(email) ? setValidEmail(false) : setValidEmail(true);

    if (name && surname && email && phone && address && birthDate && gender && levelOfEducation && howDidLearned && proficiencyInEnglish) {

      const data = new FormData();
      data.append("firstname", name);
      data.append("lastname", surname);
      data.append("email", email);
      data.append("phone", phone);
      data.append("address", address);
      data.append("birthdate", birthDate);
      data.append("gender", gender);
      data.append("levelofeducation", levelOfEducation);
      data.append("howdidlearned", howDidLearned);
      data.append("havelaptop", haveLaptopComputer);
      data.append("fulltimeemp", fullTimeEmployed);
      data.append("proenglish", proficiencyInEnglish);

      await axios({
        method: "post",
        url: "https://learnio.co/wp-json/contact-form-7/v1/contact-forms/1429/feedback",
        data: data,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          //handle success
          clearForm();
          setMsg("Thanks for your application");
          setError("");
        })
        .catch(function (response) {
          //handle error
          setMsg("");
          setError("Something went wrong");
        });
    } else {
      setError("Fields are empty");
    }
  };

  return (
    <div className={styles.formContainer}>
      <h2>Apply now</h2>
      <span>
        By applying, you can access some of the best mentoring digital skills
        courses worldwide
      </span>
      <form>
        <label htmlFor="name">First Name*</label>
        <input
          onChange={(e) => onChange(e.target)}
          value={name}
          name="name"
          type="text"
          required
          placeholder="Enter your First Name"
        />
        {validName === false && <span>Name is short</span>}
        <label htmlFor="surname">Last Name*</label>
        <input
          onChange={(e) => onChange(e.target)}
          value={surname}
          name="surname"
          required
          type="text"
          placeholder="Enter your Last Name"
        />
        {validSurname === false && <span>Last Name is short</span>}
        <label htmlFor="email">Email*</label>
        <input
          onChange={(e) => onChange(e.target)}
          value={email}
          required
          name="email"
          type="text"
          placeholder="Enter your email"
        />
        {validEmail === false && (
          <span>You have entered an invalid email address!</span>
        )}
        <label htmlFor="phone">Phone Number</label>
        <input
          onChange={(e) => onChange(e.target)}
          value={phone}
          name="phone"
          type="text"
          placeholder="Enter your phone number"
        />
        {validPhone === false && (
          <span>You have entered an invalid phone number</span>
        )}

        <label htmlFor="Birth-Date">Birth Date</label>
        <input
          onChange={(e) => onChange(e.target)}
          value={birthDate}
          name="Birth-Date"
          type="date"
        />
        {validBirthDate === false && (
          <span>You have entered an invalid Birth Date</span>
        )}

        <label htmlFor="gender">Gender</label>
        <select
          onChange={(e) => onChange(e.target)}
          value={gender}
          name="gender"
        >
          <option value=""   selected hidden>
            Your Gender
          </option>
          <option value="male"  >
            Male
          </option>
          <option value="female"  >
            Female
          </option>
          <option value="others"  >
            Others
          </option>
        </select>
        {validGender === false && <span>Select your gender</span>}

        <label htmlFor="address">Present Address</label>

        <input
          onChange={(e) => onChange(e.target)}
          value={address}
          name="address"
          type="text"
          placeholder="Your present address"
        />
        {validAddress === false && <span>Please enter a valid address</span>}

        <label htmlFor="level-of-education">Level of Education</label>
        <select
          onChange={(e) => onChange(e.target)}
          value={levelOfEducation}
          name="level-of-education"
        >
          <option value=""   selected hidden>
            Level of Education
          </option>
          <option value="Primary"  >
            Primary
          </option>
          <option value="Secondary"  >
            Secondary
          </option>
          <option value="Higher Secondary"  >
            Higher Secondary
          </option>
          <option value="Undergraduate"  >
            Undergraduate
          </option>
          <option value="Graduate"  >
            Graduate
          </option>
          <option value="Postgraduate"  >
            Postgraduate
          </option>
        </select>
        {validLevelOfEducation === false && (
          <span>Select your level of education</span>
        )}
        <label htmlFor="how-did-learned">How did you learn about us?</label>
        <select
          name="how-did-learned"
          onChange={(e) => onChange(e.target)}
          value={howDidLearned}
        >
          <option value="" selected hidden>
            Select answer
          </option>
          <option value="Website">Website</option>
          <option value="Social Media">Social Media</option>
          <option value="Online Portal">Online Portal</option>
          <option value="Media (TV, Radio, Magazine)">
            Media (TV, Radio, Magazine)
          </option>
          <option value="Another applicant">Another applicant</option>
          <option value="Local Coordinator">Local Coordinator</option>
          <option value="Other">Other</option>
        </select>
        {validHowDidLearned === false && (
          <span>Please enter a valid answer</span>
        )}
         <label htmlFor="laptop-computer">Do you have a laptop/computer and stable internet connection?</label>
        <select
          onChange={(e) => onChange(e.target)}
          value={haveLaptopComputer}
          name="laptop-computer"
        >
          <option value=""   selected hidden>
           Your Answer
          </option>
          <option value="yes"  >
            Yes
          </option>
          <option value="no"  >
            No
          </option>
        </select>
        {validHaveLaptopComputer === false && <span>Select your answer</span>}

        <label htmlFor="full-time-employed">Are you currently employed full-time?</label>
        <select
          onChange={(e) => onChange(e.target)}
          value={fullTimeEmployed}
          name="full-time-employed"
        >
          <option value=""   selected hidden>
           Your Answer
          </option>
          <option value="yes"  >
            Yes
          </option>
          <option value="no"  >
            No
          </option>
        </select>
        {validFullTimeEmployed === false && <span>Select your answer</span>}

        <label htmlFor="proficiency-in-english">Do you have a medium level of proficiency in English?</label>
        <select
          onChange={(e) => onChange(e.target)}
          value={proficiencyInEnglish}
          name="proficiency-in-english"
        >
          <option value=""   selected hidden>
           Your Answer
          </option>
          <option value="yes"  >
            Yes
          </option>
          <option value="no"  >
            No
          </option>
        </select>
        {validProficiencyInEnglish === false && <span>Select your answer</span>}

        <button
          onClick={(e) => {
            handelSubmit(e);
          }}
        >
          Submit
        </button>
        {(msg || error) && (
          <span className={msg ? styles.msg : styles.error}>
            {msg ? msg : error}
          </span>
        )}
      </form>
    </div>
  );
}
