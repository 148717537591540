import React from "react";
import styles from "./style.module.scss";
import CorrectIcon from "../../atoms/icons/apply/correct";
import Form from "./form";

const info = [
  "Responsible Freedom: Want to finish earlier on Friday to enjoy the good weather? No problem! You can make up for that time later in the night or on the weekend. ",
  "Transparency: You speak your mind and you are honest in all your business transactions with customers, suppliers, partners, and colleagues.",
  "Diversity: Our team and our clients are scattered all around the world, and we have great respect for other cultures, regions and customs.",
  "Inclusivity: We are good listeners and we love generating solutions and collecting everyone’s ideas!",
  "Ambition: We are constantly focused on being better and doing our best. We are also very curious and we update ourselves with the latest technology and solutions.",
  "Creativity: We think out of the box, especially when looking for solutions. We always challenge the status quo with views to come up with better processes.",
];
const rules = [
  "A Khulna youth living inside Khulna, preferably inside Khulna city",
  "18 – 35 years old",
  "Currently not in full-time employment",
  "Proficient in English",
  "Equipped with access to a computer and stable internet",
];

export default function index() {
  return (
    <div className={styles.applyContent}>
      <div className={styles.applyContainer}>
      <div className={styles.info}>
          <span>Before you apply, please check if you are eligible for our programs. <br/>
            To be considered eligible for our programs you need to be:</span>
          <div className={styles.points}>
            {rules.map((res, index) => (
              <span className={styles.pointText} key={index}>
                <CorrectIcon />
                {res}
              </span>
            ))}
          </div>
        </div>
        <h2 className={styles.title}>
        If you fulfill all of the criteria mentioned above, please apply using the form below:
        </h2>
        <Form />

        
      </div>
    </div>
  );
}
