import React from 'react'
import Header from "../components/molecules/header";
import Footer from "../components/molecules/footer";
import Banner from '../components/molecules/khulnaBanner';
import InformationApply from '../components/molecules/applyFormKhulna';
import Head from "../components/organisms/head";


export default function apply() {

    const moduleData = {
        title: "Learnio Khulna <br/> <span class='seconday' >powered by MAERSK </span><br/><span>Upskilling Khulna's youth for a digital future</span>",
      };
      const data ={
        title:'Apply Now Khulna',
        description:' Use this application form to apply for free training courses to further your skills.',
        image:'',
        keywords:'apply now,apply you,apply now at,now apply,apply now for,apply now to,applied now,apply for now, i apply now'
    }
  return (
    <div>
      <Head data={data} url={'khulna/'}/>
        <Header showButton={false} />
        <div id="apply-now-banner">
          <Banner moduleData={moduleData} template="apply" />
        </div>
        <InformationApply />
        <Footer  noApply={true}/>
    </div>
  )
}
